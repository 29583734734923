/** @jsxImportSource theme-ui */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Box, Container, Flex, Text, Heading } from "theme-ui";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ThemedMarkdown, { ThemedMarkdownLight } from "../components/themed-markdown";
import OttCircle from "../components/ott-circle";

export const HoergeraetePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const heroImageData = getImage(frontmatter.hero.image);
  const hearingAidsImageData = getImage(frontmatter.hearingaidsimage);
  return (
    <Layout
      activeNav="hg"
      seo={{ title: frontmatter.title, description: frontmatter.seoDescription }}
    >
      <Container my={5}>
        <Heading as="h1" variant="styles.h2" mb={4}>
          {frontmatter.heading}
        </Heading>
        <GatsbyImage image={heroImageData} alt={frontmatter.hero.alttext} />
        <Box>
          <Flex sx={{ flexDirection: ["column", "column", "row"], gap: 3 }} my={4}>
            <Box sx={{ flex: "1 1 0" }}>
              <Heading as="h3" variant="styles.h4" sx={{ color: "primary" }}>
                {frontmatter.hearingaidssteps.heading}
              </Heading>
            </Box>
            <Box sx={{ flex: "1 1 0" }} ml={[1, 2, 4]}>
              <Text>{frontmatter.hearingaidssteps.subheading}</Text>
            </Box>
          </Flex>
          <Flex sx={{ flexDirection: ["column", "row", "row"], gap: [3, 5] }} mb={5}>
            {frontmatter.hearingaidssteps.points.map((step, i) => (
              <Box sx={{ flex: "1 1 0" }}>
                <OttCircle>{i + 1}</OttCircle>
                <ThemedMarkdown html={step.t} />
              </Box>
            ))}
          </Flex>
        </Box>
        {frontmatter.blocks.map((block, i) => (
          <Flex key={i} sx={{ flexDirection: ["column", "column", "row"], gap: [3, 3, 4] }} my={3}>
            <Box
              sx={{
                order: block.flip ? [1, 1, 1] : [1, 1, 0],
                flex: "2 2 0",
              }}
            >
              <Heading as="h2" variant="styles.h4" sx={{ color: "primary" }}>
                {block.pointsHeading}
              </Heading>
              <ul>
                {block.points.map((point) => (
                  <li key={point.t}>
                    <Text>{point.t}</Text>
                  </li>
                ))}
              </ul>
            </Box>
            <Box
              sx={{
                order: block.flip ? [0, 0, 0] : [0, 0, 1],
                flex: "3 3 0",
                backgroundColor: "primary",
              }}
            >
              <ThemedMarkdownLight
                html={block.text}
                sxpass={{
                  backgroundColor: "primary",
                  color: "gray.1",
                  padding: 5,
                }}
              />
            </Box>
          </Flex>
        ))}
        <Flex sx={{ flexDirection: ["column", "column", "row"], gap: 3 }} my={2}>
          <Box sx={{ flex: "1 1 0" }}>
            <GatsbyImage image={hearingAidsImageData} alt={frontmatter.hearingaidsalt} />
          </Box>
          <Box sx={{ flex: "1 1 0", backgroundColor: "primary" }}>
            <ThemedMarkdownLight
              html={frontmatter.hearingsaidstext}
              sxpass={{
                backgroundColor: "primary",
                color: "gray.1",
                padding: 5,
              }}
            />
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default HoergeraetePage;

export const pageQuery = graphql`
  query HoergeraetePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "hoergeraete-page" } }) {
      frontmatter {
        title
        seoDescription
        hero {
          image {
            childImageSharp {
              gatsbyImageData(quality: 92, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          alttext
        }
        heading
        blocks {
          pointsHeading
          points {
            t
          }
          text
          flip
        }
        hearingaidssteps {
          heading
          subheading
          points {
            t
          }
        }
        hearingaidsimage {
          childImageSharp {
            gatsbyImageData(quality: 92, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        hearingaidsalt
        hearingsaidstext
      }
    }
  }
`;
