/** @jsxImportSource theme-ui */
import React from "react";
import { Box } from "theme-ui";

const OttCircle = ({ children }) => {
  return (
    <Box
      sx={{
        borderRadius: "50%",
        border: (theme) => `2px solid ${theme.colors.primary}`,
        color: "primary",
        width: "52px",
        height: "52px",
        padding: "8px",
        fontSize: "32px",
        textAlign: "center",
        margin: "auto",
        marginBottom: [2, 3, 4],
        lineHeight: 1,
      }}
    >
      {children}
    </Box>
  );
};

export default OttCircle;
